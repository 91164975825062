/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RemoteClassroomsFile } from '../types/remote';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  PLATFORM;
  LETTER_TO_SEARCH: string;
  TEXT_TO_SHOW: string;

  IS_ONLINE: boolean;
  SHOW_LOADING: boolean;
  SHOW_ERROR: boolean;
  SHOW_NO_CALENDAR: boolean;

  CLASSROOMS_OBJECT: RemoteClassroomsFile;

  MIN_DATE: Date = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0);
  MAX_DATE: Date = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0, 0);

  private DEFAULT_PAGE_TO_SHOW = new BehaviorSubject(1);
  private TYPE_TO_SEARCH = new BehaviorSubject<'DOCENTI' | 'STUDENTI' | 'AULE'>(
    'STUDENTI'
  );

  constructor() {}

  getDefaultPage = () => this.DEFAULT_PAGE_TO_SHOW.asObservable();
  setDefaultPage = (page) => this.DEFAULT_PAGE_TO_SHOW.next(page);

  getTypeToSearch = () => this.TYPE_TO_SEARCH.asObservable();
  setTypeToSearch = (type) => this.TYPE_TO_SEARCH.next(type);
}
