/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { getWeek } from 'date-fns';
import { Subscription } from 'rxjs';
import { CommonService } from './common.service';

// Types
import { RemoteClassroomsFile, RemoteDatesFile } from '../types/remote';

@Injectable({
  providedIn: 'root',
})
export class DateUtilsService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  URL_TO_DISPLAY;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  TYPE_TO_SEARCH: 'DOCENTI' | 'STUDENTI' | 'AULE';

  values: RemoteDatesFile;

  WEEK_TO_DISPLAY: number;

  private sub: Subscription;

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {
    this.sub = this.commonService
      .getTypeToSearch()
      .subscribe((data) => (this.TYPE_TO_SEARCH = data));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  obtainLetterFromWeek = (weekNumber, mustSkipFind = false) => {
    const weekLetter = !mustSkipFind
      ? this.values.data.find((record) => record.nsett === weekNumber)?.codsett
      : weekNumber;
    this.WEEK_TO_DISPLAY = weekLetter;
    switch (weekLetter) {
      case 1:
      case 2:
      case 3:
        return 'X';
        break;
      case 4:
        return 'Y';
        break;
      case 5:
      case 6:
        return 'Z';
        break;
      default:
        this.commonService.SHOW_NO_CALENDAR = true;
        return 'N';
        break;
    }
  };

  getDataFromServer = () =>
    this.httpClient.get('https://orario.vallauri.edu/data/settimane.json', {
      responseType: 'json',
    });

  getClassroomFromServer = () =>
    this.httpClient.get('https://orario.vallauri.edu/data/aule.json', {
      responseType: 'json',
    });

  getMonday(d) {
    d = new Date(d);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  setLetter = (date: Date | number) => {
    let week: number = getWeek(this.getMonday(date), { weekStartsOn: 1 });
    if (new Date((date as Date).getFullYear(), 0, 1).getDay() !== 1) {
      week--;
    }
    const letter = this.obtainLetterFromWeek(
      typeof date !== 'number' ? week : date,
      typeof date === 'number'
    );
    if (letter !== 'N') {
      this.commonService.SHOW_NO_CALENDAR = false;
      this.commonService.TEXT_TO_SHOW = `Settimana ${this.WEEK_TO_DISPLAY}`;
      this.commonService.LETTER_TO_SEARCH = letter;
      this.setLink();
    }
  };

  setLink = () => {
    if (
      this.URL_TO_DISPLAY !==
      `https://orario.vallauri.edu/files/${this.commonService.LETTER_TO_SEARCH}_${this.TYPE_TO_SEARCH}.pdf`
    ) {
      this.commonService.SHOW_LOADING = true;
      this.commonService.SHOW_ERROR = false;
      this.commonService.SHOW_NO_CALENDAR = false;
      // eslint-disable-next-line max-len
      this.URL_TO_DISPLAY = `https://orario.vallauri.edu/files/${this.commonService.LETTER_TO_SEARCH}_${this.TYPE_TO_SEARCH}.pdf`;
    }
  };

  loadFilesFromServer(): Promise<void> {
    return this.getClassroomFromServer()
      .toPromise()
      .then((data: RemoteClassroomsFile) => {
        this.commonService.CLASSROOMS_OBJECT = data;
      })
      .catch((err) => {
        console.error(err);
        this.commonService.SHOW_ERROR = true;
      });
  }
}
