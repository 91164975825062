/* eslint-disable @typescript-eslint/naming-convention */
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { MenuController, Platform } from '@ionic/angular';
import { CommonService } from './services/common.service';
import { DateUtilsService } from './services/date-utils.service';

// Types
import { RemoteClassroomsFile, RemoteDatesFile } from './types/remote';
import { Browser } from '@capacitor/browser';
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  appPages = [
    { title: 'Settimana 1', icon: 'calendar', value: 1 },
    { title: 'Settimana 2', icon: 'calendar', value: 2 },
    { title: 'Settimana 3', icon: 'calendar', value: 3 },
    { title: 'Settimana 4', icon: 'calendar', value: 4 },
    { title: 'Settimana 5', icon: 'calendar', value: 5 },
    { title: 'Settimana 6', icon: 'calendar', value: 6 },
  ];

  choosenDate: string = new Date().toISOString();
  constructor(
    public dateUtils: DateUtilsService,
    public commonService: CommonService,
    private platform: Platform,
    private menuController: MenuController,
    private ngZone: NgZone
  ) {
    this.platform.ready().then(async () => {
      this.commonService.PLATFORM = Capacitor.getPlatform();
      this.commonService.IS_ONLINE = (await Network.getStatus()).connected;
      this.commonService.SHOW_LOADING = true;
      const tempDate = new Date();
      tempDate.setHours(new Date().getHours() + 2);
      this.choosenDate = tempDate.toISOString();
      this.dateUtils.getDataFromServer().subscribe({
        next: (data: RemoteDatesFile) => {
          this.commonService.SHOW_LOADING = false;
          const startDateParts = data.datainizio.split('/');
          const endDateParts = data.datafine.split('/');

          this.commonService.MIN_DATE = new Date(
            +startDateParts[2],
            +startDateParts[1] - 1,
            +startDateParts[0]
          );

          this.commonService.MAX_DATE = new Date(
            +endDateParts[2],
            +endDateParts[1] - 1,
            +endDateParts[0]
          );

          this.dateUtils.values = data;
          this.setDate();
        },
        error: (err) => {
          console.error(err);
          this.commonService.SHOW_ERROR = true;
        },
      });
    });
  }

  get canEnableCalendar() {
    return !(
      this.dateUtils?.values?.datainizio && this.dateUtils?.values?.datafine
    );
  }

  ngOnInit(): void {
    Network.addListener('networkStatusChange', (status) => {
      this.ngZone.run(() => {
        if (status.connected !== this.commonService.IS_ONLINE) {
          this.commonService.SHOW_ERROR = false;
          this.commonService.SHOW_LOADING = status.connected;
        }
        this.commonService.IS_ONLINE = status.connected;
      });
      console.log('CONNECTION_CHECK', 'STATUS:' + status.connected);
    });
    this.menuController.swipeGesture(false);
  }

  setToday = (
    value: Event | { detail: { value: string } } | any = {
      detail: { value: new Date().toISOString() },
    }
  ) => {
    const tempDate = new Date(value.detail.value);
    tempDate.setHours(new Date().getHours() + 2);
    const inputDate = tempDate;

    this.choosenDate = inputDate.toISOString();
    this.setDate(value);
  };

  setDate = (
    value: Event | { detail: { value: string } } | any = {
      detail: { value: new Date().toISOString() },
    }
  ) => {
    const tempDate = new Date(value.detail.value);
    tempDate.setHours(new Date().getHours() + 2);
    const inputDate = tempDate;

    if (
      inputDate >= this.commonService.MAX_DATE ||
      inputDate <= this.commonService.MIN_DATE
    ) {
      this.commonService.SHOW_NO_CALENDAR = true;
    } else {
      this.dateUtils.setLetter(inputDate);
      this.menuController.close();
    }
  };

  canEnableDate = (dateString: string = new Date().toISOString()) => {
    const date = new Date(dateString);
    return (
      date <= this.commonService.MAX_DATE && date >= this.commonService.MIN_DATE
    );
  };

  setDirectURL = (URL: string, textToDisplay) => {
    if (this.dateUtils.URL_TO_DISPLAY !== URL) {
      this.dateUtils.URL_TO_DISPLAY = URL;
      this.commonService.TEXT_TO_SHOW = textToDisplay;
      this.commonService.SHOW_LOADING = true;
      this.commonService.SHOW_ERROR = false;
      this.commonService.SHOW_NO_CALENDAR = false;
    }
    this.menuController.close();
  };

  changeSRC = (value, textToDisplay) => {
    this.dateUtils.setLetter(value);
    this.commonService.TEXT_TO_SHOW = textToDisplay;
    this.menuController.close();
  };

  // setEveningCalendar = () => {
  //   this.commonService.LETTER_TO_SEARCH = 'S';
  //   this.dateUtils.setLink();
  //   this.menuController.close();
  // };

  async openPolicy(): Promise<void> {
    await Browser.open({
      url: environment.privacyPolicyUrl,
      toolbarColor: 'primary',
    });
  }

  ngOnDestroy(): void {
    Network.removeAllListeners();
  }
}
