<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu
      side="start"
      menuId="start"
      contentId="main-content"
      type="overlay"
    >
      <ion-content>
        <ion-list-header>
          <ion-item class="ion-justify-content-center" lines="none" id="logo">
            <ion-img src="assets/vallauriLogo.png"></ion-img>
          </ion-item>
        </ion-list-header>

        <!-- Giorno -->
        <div>
          <ion-row class="ion-margin-vertical">
            <ion-col size="6" offset="3" class="ion-full-width">
              <ion-button
                [disabled]="!canEnableDate()"
                color="primary"
                class="ion-full-width"
                (click)="setToday()"
              >
                <ion-icon slot="start" name="today-outline"></ion-icon>
                <ion-label> Oggi </ion-label>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-datetime
            [disabled]="canEnableCalendar"
            [isDateEnabled]="canEnableDate"
            presentation="date"
            pickerFormat="DD MM YYYY"
            class="ion-full-width ion-full-height"
            presentation="date"
            [(ngModel)]="choosenDate"
            (ionChange)="setDate($event)"
            [firstDayOfWeek]="1"
          ></ion-datetime>
        </div>
        <ion-accordion-group [value]="''">
          <ion-accordion value="weeks">
            <ion-item slot="header" color="light">
              <ion-label>Settimane</ion-label>
            </ion-item>
            <div slot="content">
              <ion-item
                class="highlight"
                *ngFor="let p of appPages; let i = index"
                (click)="changeSRC(p.value, 'Settimana ' + p.value)"
                lines="none"
                detail="false"
              >
                <ion-icon
                  slot="start"
                  [ios]="p.icon + '-outline'"
                  [md]="p.icon + '-sharp'"
                ></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </div>
          </ion-accordion>
        </ion-accordion-group>

        <!-- Sabati -->
        <ion-accordion-group [value]="''">
          <ion-accordion value="saturdays">
            <ion-item slot="header" color="light">
              <ion-label>Sabati</ion-label>
            </ion-item>
            <div slot="content">
              <ion-item
                (click)="changeSRC(1, 'Sabati chiusi')"
                class="highlight"
                lines="none"
                detail="false"
              >
                <ion-icon slot="start" ios="close-outline" md="close-sharp"
                  >></ion-icon
                >
                <ion-label> Chiuso </ion-label>
              </ion-item>
              <ion-item
                (click)="changeSRC(4, 'Sabati solo prime')"
                class="highlight"
                lines="none"
                detail="false"
              >
                <ion-icon
                  slot="start"
                  ios="notifications-outline"
                  md="notifications-sharp"
                ></ion-icon>
                <ion-label> Solo prime </ion-label>
              </ion-item>
              <ion-item
                (click)="changeSRC(6, 'Sabati aperti')"
                class="highlight"
                lines="none"
                detail="false"
              >
                <ion-icon
                  slot="start"
                  ios="checkmark-outline"
                  md="checkmark-sharp"
                ></ion-icon>
                <ion-label> Aperto </ion-label>
              </ion-item>
            </div>
          </ion-accordion>
        </ion-accordion-group>

        <!-- Calendari -->
        <ion-accordion-group [value]="''">
          <ion-accordion value="calendars">
            <ion-item slot="header" color="light">
              <ion-label>Calendari e Scansione</ion-label>
            </ion-item>
            <div slot="content">
              <ion-menu-toggle
                auto-hide="false"
                (click)="
                  setDirectURL(
                    'https://orario.vallauri.edu/files/CAL_LICEO.pdf',
                    'Liceo'
                  )
                "
              >
                <ion-item lines="none" detail="false">
                  <ion-icon
                    slot="start"
                    ios="school-outline"
                    md="school-sharp"
                  ></ion-icon>
                  <ion-label> Liceo </ion-label>
                </ion-item>
              </ion-menu-toggle>
              <ion-menu-toggle
                auto-hide="false"
                (click)="
                  setDirectURL(
                    'https://orario.vallauri.edu/files/CAL_PRIME.pdf',
                    'Prime INF / ELT / MEC'
                  )
                "
              >
                <ion-item lines="none" detail="false">
                  <ion-icon
                    slot="start"
                    ios="school-outline"
                    md="school-sharp"
                  ></ion-icon>
                  <ion-label> Prime INF / ELT / MEC </ion-label>
                </ion-item>
              </ion-menu-toggle>
              <ion-menu-toggle
                auto-hide="false"
                (click)="
                  setDirectURL(
                    'https://orario.vallauri.edu/files/CAL_TUTTE.pdf',
                    'Tutte le altre classi'
                  )
                "
              >
                <ion-item lines="none" detail="false">
                  <ion-icon
                    slot="start"
                    ios="school-outline"
                    md="school-sharp"
                  ></ion-icon>
                  <ion-label> Tutte le altre classi </ion-label>
                </ion-item>
              </ion-menu-toggle>

              <ion-menu-toggle
                auto-hide="false"
                (click)="
                  setDirectURL(
                    'https://orario.vallauri.edu/files/SCANSIONE_ORARIA.pdf',
                    'Scansione oraria'
                  )
                "
              >
                <ion-item lines="none" detail="false">
                  <ion-icon
                    slot="start"
                    ios="school-outline"
                    md="school-sharp"
                  ></ion-icon>
                  <ion-label> Scansione oraria </ion-label>
                </ion-item>
              </ion-menu-toggle>

              <!-- <ion-menu-toggle
                auto-hide="false"
                (click)="
                  setDirectURL(
                    'https://orario.vallauri.edu/files/CAL_TUTTE.pdf',
                    'Corso serale'
                  )
                "
              >
                <ion-item lines="none" detail="false">
                  <ion-icon
                    slot="start"
                    ios="school-outline"
                    md="school-sharp"
                  ></ion-icon>
                  <ion-label> Corso serale </ion-label>
                </ion-item>
              </ion-menu-toggle> -->
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-content>

      <ion-footer>
        <ion-menu-toggle
          id="open-calendar"
          auto-hide="false"
          class="removeClick"
        >
          <ion-item
            class="highlight ion-no-padding"
            lines="none"
            detail="false"
          >
            <ion-row class="ion-full-width">
              <ion-col
                size="12"
                class="ion-full-width ion-text-center ion-align-items-center ion-justify-content-middle"
              >
                <ion-note class="ion-no-padding smallerText interlinea">
                  COPYRIGHT 2022-2024, tutti i diritti riservati ©<br />
                  <i> Racca Paolo </i>
                  <br />
                  <a
                    class="addClick"
                    (click)="openPolicy()"
                  >
                    Privacy Policy
                  </a>
                </ion-note>
              </ion-col>
            </ion-row>
          </ion-item>
        </ion-menu-toggle>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
